@import url(https://fonts.googleapis.com/css?family=Source+Code+Pro);
.App {
  font-family: Helevtica;
  text-align: center;
}

body {
  margin: 0;
  height: 100%;
  padding: 0;
  font-size: 14px;
  color: #000000;
  background-color: #212121;
  color: rgba(255, 255, 255, 0.75);
}

/* Global */

html {
  overflow: scroll;
}
::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}

.Particles-js {
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-position: "center";
  background-size: "cover";
  background-repeat: "no-repeat";
  height: 100%;
  display: "flex";
}

.headertext {
  color: white;
  position: absolute;
  text-align: center;
  top: 50%;
  width: 100%;
  line-height: 0.4em;
  text-shadow: 0 0 60px 10px rgba(0, 0, 0, 0.2);
  -webkit-transition: text-shadow 0.4s, -webkit-transform 0.4s;
  transition: text-shadow 0.4s, -webkit-transform 0.4s;
  transition: text-shadow 0.4s, transform 0.4s;
  transition: text-shadow 0.4s, transform 0.4s, -webkit-transform 0.4s;
  overflow: wrap;
  font-weight: 800;
  font-size: 5.6vh;
}

.UserResume {
  width: 75%;
  height: 80vh;
}
.moveResume {
  margin-top: -94vh;
}

h2 {
  font-size: 40px;
  margin-top: -15%;
}

.ContactText {
  color: white;
  position: absolute;
  text-align: center;
  top: 41%;
  width: 100%;
  line-height: 0.4em;
  text-shadow: 0 0 60px 10px rgba(0, 0, 0, 0.2);
  -webkit-transition: text-shadow 0.4s, -webkit-transform 0.4s;
  transition: text-shadow 0.4s, -webkit-transform 0.4s;
  transition: text-shadow 0.4s, transform 0.4s;
  transition: text-shadow 0.4s, transform 0.4s, -webkit-transform 0.4s;
  overflow: wrap;
  font-weight: 800;
  font-size: 52px;
}

.AboveText {
  font-size: 20px;
  font-weight: 400;
}

#emailSubject {
  margin-left: 1vh;
  margin-top: 10px;
  background-color: transparent;
  color: #fff;
  font-size: 18px;
  border-radius: 5px;
  border: 15px solid "#333333";
  padding: 13px;
  margin-bottom: 1%;
  width: 20%;
  outline: 0;
  position: relative;
}

#yourEmail {
  margin-left: 1vh;
  margin-top: 10px;
  background-color: transparent;
  color: #fff;
  font-size: 18px;
  border-radius: 5px;
  border: 15px solid "#333333";
  padding: 13px;
  margin-bottom: 1%;
  width: 20%;
  outline: 0;
  position: relative;
}

#emailMessage {
  margin-top: 30px;
  color: #fff;
  background-color: transparent;
  font-size: 18px;
  border-radius: 5px;
  border: 15px solid "#333333";
  padding: 13px;
  margin-bottom: 35px;
  width: 22%;
  height: 22vh;
  outline: 0;
  position: relative;
}

.btn {
  margin-bottom: 0%;
}

.btn {
  -webkit-tap-highlight-color: transparent;
  outline: None;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 10px;

  box-shadow: 0 0 30px 5px rgba(255, 255, 255, 0.2);
  box-sizing: inherit;
  color: white;
  cursor: pointer;
  display: inline-block;
  height: 40px;
  letter-spacing: 0.5px;
  line-height: 42px;
  padding: 0px 1rem;
  pointer-events: all;
  overflow: hidden;
  border: None;

  width: 10%;
  margin: 10px;
  /* DO WE WANT THE TRANSFORM INITIALLY CAUSES ISSUES*/
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);

  -webkit-transition: box-shadow 0.4s, -webkit-transform 0.4s;

  transition: box-shadow 0.4s, -webkit-transform 0.4s;

  transition: box-shadow 0.4s, transform 0.4s;

  transition: box-shadow 0.4s, transform 0.4s, -webkit-transform 0.4s;
}

.btn:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

#provideEmail {
  margin-left: -3%;
  justify-content: center;
  align-items: center;
  position: 0;
}

#Subject {
  margin-left: -3%;
  justify-content: center;
  align-items: center;
  position: 0;
}

.theGrid {
  top: 30%;
  width: 100%;
  position: absolute;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

